.form_main{
    .gf_browser_ie.gform_wrapper .gform_footer input.button{
        padding: rem-calc(13 26 15 29);
    }
    .gform_wrapper ul.gform_fields li.gfield{
        margin: rem-calc(0 0 18 0);
        padding: 0;
        &:before{
            display: none;
        }
    }
    .gform_wrapper .ginput_container_fileupload{
        width: auto;
        display: inline-block;
        margin: 0 auto;
        position: relative;
    }
    .gform_wrapper ul.gform_fields li.upload-file{
        text-align: center;
    }

    .gform_wrapper .ginput_container_fileupload input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type='file']).txtbox{
        max-width: rem-calc(240);
        background-color: transparent;
        border: rem-calc(1) solid $white !important;
        border-radius: rem-calc(24) !important;
        font-family: $barlow !important;
        font-weight: $black !important;
        font-size: rem-calc(20) !important;
        line-height: rem-calc(24) !important;
        color: $white !important;
        text-transform: uppercase;
        padding: rem-calc(26 10 27 10) !important;
        text-align: center;
        display: inline-block;
    }
    .gform_wrapper .top_label .gfield_label{
        display: none;
    }
    .gform_wrapper input{
        padding: 0;
        margin: 0;
        width: 100%;
        box-shadow: none;
        height: rem-calc(40);
        box-shadow: none !important;
        border-radius: 0 !important;
        margin: 0 !important;
    }
    .gform_wrapper .top_label input.medium{
        width: 100%;
    }
    .gform_wrapper textarea{
        padding: rem-calc(21 17);
        margin: 0;
        height: rem-calc(215) !important;
        font-family: $barlow;
        font-weight: $regural;
        font-size: rem-calc(16);
        line-height: rem-calc(18);
        color: $light_gray;
        letter-spacing: rem-calc(1.8);
        box-shadow: none !important;
        border-radius: 0 !important;
        border: 0;
    }
    .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
        padding: rem-calc(0 17);
        font-family: $barlow;
        font-weight: $regural;
        font-size: rem-calc(16);
        line-height: rem-calc(18);
        color: $light_gray;
        box-shadow: none !important;
        border-radius: 0 !important;
        border: 0;
        letter-spacing: rem-calc(1.6);
    }
    .gform_wrapper .gform_footer{
        margin: rem-calc(0 0 0 0);
        padding: rem-calc(4 0 0 0);
        text-align: center;
    }
    .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer .gform_button{
        font-size: rem-calc(20);
        line-height: rem-calc(24);
        font-weight: $black;
        color: $white;
        text-transform: uppercase;
        border: rem-calc(1) solid $orange;
        background-color: $orange;
        border-radius: rem-calc(22) !important;
        display: inline-block;
        padding: rem-calc(13 26 15 29);
        letter-spacing: rem-calc(1.4);
        height: auto !important;
        @include transition(.5s, all, ease);
        &:hover{
            background-color: $teal;
            border-color: $teal;
            color: $white;
        }
        &:focus{
            background-color: $teal;
            border-color: $teal;
            color: $white;
        }        
    }

    .gform_wrapper div.validation_error{
        display: none;
    }
    .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container{
        margin-top: 0;
    }
    .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
        width: 100% !important;
        border: 0;
        background-color: transparent;
        max-width: 100% !important;
    }
    .gform_wrapper.gform_validation_error .top_label input.medium{
        width: 100%;
        margin: 0;
    }
    .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select{
        margin-left: 0;
    }
    .gform_wrapper .top_label select.medium{
        width: 100%;
    }
    .gform_wrapper.gf_browser_chrome select{
        margin: 0;
        padding: rem-calc(0 18);
    }
    .gform_wrapper select{
        padding: rem-calc(0 17);
        font-family: $barlow;
        font-weight: $regural;
        font-size: rem-calc(16);
        line-height: rem-calc(18);
        color: $light_gray;
        box-shadow: none !important;
        border-radius: 0 !important;
        border: 0;
        letter-spacing: rem-calc(1.6);
        background-position: right center;
        box-shadow: none !important;
        border-radius: 0 !important;
        cursor: pointer;
        background-image: url(../../../src/assets/images/gray-arrow.png);
        background-size: rem-calc(13 11);
    }
    .gform_wrapper select:focus{
        box-shadow: none;
    }
    .gform_wrapper.gform_validation_error .top_label select.medium{
        width: 100%;
    }
    .gform_wrapper .validation_message{
        color: $red;
        padding: rem-calc(0 0 5 0) !important;
        font-family: $barlow;
        font-weight: $regural;
        font-size: rem-calc(16);
        line-height: rem-calc(20);
    }
    .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
        border: 0;
    }

    .screen-reader-text{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        clip: auto;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 9;
        input{
            cursor: pointer;
        }
    }
    .browse_btn{
        font-family: $barlow;
        font-weight: $regural;
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        position: absolute;
        right: rem-calc(8);
        top: rem-calc(7);
        background-color: $gray;
        color: $blackcolor;
        padding: rem-calc(2 10 2 10);
        display: none !important;
        + .validation_message{
            padding: 0 !important;
        }
    }
}
body .form_main .gform_wrapper .top_label div.ginput_container{
    margin-top: 0;
}
body .gform_wrapper{
    margin: 0;
}
.gform_confirmation_message{
    font-family: $barlow;
    font-weight: $regural;
    font-size: rem-calc(18);
    line-height: rem-calc(20);
    color: $green;
    text-align: center;
}


.careers_form_sec{
    .form_main .gform_wrapper .gform_footer{
        position: absolute;
        right: 0;
        bottom: 0;
        width: auto;
    }
    .gform_wrapper form{
        position: relative;
    }
    @include breakpoints(x-small){
        .form_main .gform_wrapper .gform_footer{
            position: static;
        }
    }
}

.form_main .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit]{
    width: auto;
}

.form_main .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
    line-height: normal;
    min-height: inherit;
}

.contact_form_wrap{
    @include breakpoints(extra-small){
        .form_main .gform_wrapper .gform_footer .gform_button{
            padding: rem-calc(10 15 13 15);
            font-size: rem-calc(18);
            line-height: rem-calc(22);
            letter-spacing: rem-calc(0.8);
        }
    }
}

.form_main .gform_wrapper ul.gform_fields li.gfield.gfield_error+li.gfield.gfield_error {
    margin-top: 0px;
}