.market_served_sec{
    padding: rem-calc(17 0 116 0);
    background-image: url(../../../src/assets/images/rebel.png);
    position: relative;
    &:before{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.92) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 );
    }
    .market_served_wrap{
        position: relative;
        .market_served_heading{
            text-align: center;
            padding: rem-calc(0 0 78 0);
            h2{
                font-size: rem-calc(50);
                line-height: rem-calc(60);
                font-weight: $black;
                color: $white;
                text-transform: uppercase;
                letter-spacing: rem-calc(3.3);
                margin: rem-calc(0 0 10 0);
            }
            p{
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                font-weight: $regural;
                color: $white;
            }
        }
        .market_served_block{
            height: rem-calc(315);
            position: relative;
            display: flex;
            align-items: center;
            text-align: center;
            overflow: hidden;
            a{
                display: block;
                width: 100%;
                .market_served_img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                    @include transition(.5s, all, ease);
                    &:before{
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $blackcolor;
                        opacity: 0.7
                    }
                }
                .market_served_text{
                    position: relative;
                    h3{
                        font-size: rem-calc(45);
                        line-height: rem-calc(54);
                        font-weight: $black;
                        color: $off_white;
                        text-transform: uppercase;
                        letter-spacing: rem-calc(2.8);
                        margin: rem-calc(0 0 11 0);
                        @include transition(.5s, all, ease);
                    }
                }
                &:hover{
                    .market_served_img{
                        transform: scale(1.1);
                    }
                    .market_served_text{
                        h3{
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    @include breakpoints(small){
        padding: rem-calc(17 0 40 0);
        .market_served_wrap{
            .market_served_block{
                margin: rem-calc(0 0 30 0);
                height: rem-calc(200);
            }
        }
    }
    @include breakpoints(x-small){
        .market_served_wrap{
            .market_served_heading{
                padding: rem-calc(0 0 50 0);
                h2{
                    font-size: rem-calc(36);
                    line-height: rem-calc(46);
                }
                p{
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                }
            }
            .market_served_block{
                a{
                    .market_served_text{
                        h3{
                            font-size: rem-calc(34);
                            line-height: rem-calc(40);
                        }
                    }
                }
            }
        }
    }
}