@charset 'utf-8';


@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Global
@import "global/accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/wp-overrides";
@import "global/gutenberg";

// Modules
@import "modules/navigation";
@import "modules/content";
@import "modules/footer";
@import "modules/editor-style";
@import "modules/global";
@import "modules/menu";
@import "modules/animate";
@import "modules/style";


// Content Module
@import "content-module/banner_section";
@import "content-module/why_airship_sec";
@import "content-module/market_served_sec";
@import "content-module/grid_layout_block";
@import "content-module/inner_banner";
@import "content-module/page_content_sec";
@import "content-module/list_block_sec";
@import "content-module/managed_service";
@import "content-module/list_columns_block";
@import "content-module/image_content_block";
@import "content-module/resource_block";
@import "content-module/description_block_sec";
@import "content-module/news_insights_sec";
@import "content-module/order_list_sec";
@import "content-module/support_table_sec";
@import "content-module/accordion_sec";
@import "content-module/form_sec";
@import "content-module/footer";
@import "content-module/header";

// Components
@import "components/buttons";
@import "components/cards";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";

// Templates
@import "templates/front";
@import "templates/kitchen-sink";
