.zigzag_main{
    .zigzag_block{
        position: relative;
        &.gray{
            background-color: $gray;
            .zigzag_content{
                h2{
                    color: $blue;
                }
                h3{
                    color: $blue;
                }
                p{
                    color: $blue;
                }
                .primary_button{
                    color: $blue;
                    border-color: $blue;
                }
            }
        }
        &.teal{
            background-color: $teal;
        }
        &.blue{
            background-color: $blue;
        }
        .side_img_div{
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 35%;
            background-position: center center;
            background-size: cover;
            &:before{
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: $blackcolor;
                opacity: 0.2;
            }
        }
        .zigzag_content{
            max-width: 59%;
            padding: rem-calc(89 0 80 0);
            h2{
                font-size: rem-calc(45);
                line-height: rem-calc(54);
                font-weight: $black;
                color: $white;
                margin: rem-calc(0 0 16 0);
                letter-spacing: rem-calc(1.9);
            }
            h3{
                font-size: rem-calc(30);
                line-height: rem-calc(36);
                font-weight: $light;
                color: $white;
                margin: rem-calc(0 0 14 0);
            }
            p{
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                font-weight: $regural;
                color: $white;
                margin: rem-calc(0 0 27 0);
                &:last-of-type{
                    margin: rem-calc(0 0 0 0);
                }
            }
            .primary_button{
                font-size: rem-calc(20);
                line-height: rem-calc(24);
                font-weight: $light;
                color: $white;
                border-color: $white;
                background-color: transparent;
                letter-spacing: 0;
                text-transform: inherit;
                padding: rem-calc(12 22 13 22);
                @include transition(.5s, font-weight, ease-in-out);
                margin: rem-calc(25 0 0 0);
                &:hover{
                    font-weight: $semibold;
                }
            }
        }
        &:nth-child(even){
            .side_img_div{
                right: inherit;
                left: 0;
            }
            .zigzag_content{
                margin-left: auto;
                padding: rem-calc(116 0 111 4);
                max-width: 60%;
            }
        }
    }
    &.left{
        .zigzag_block{
            .side_img_div{
                left: 0;
                right: inherit;
            }
            .zigzag_content{
                margin-left: auto;
            }
            &:nth-child(even){
                .side_img_div{
                    right: 0;
                    left: inherit;
                }
                .zigzag_content{
                    margin-left: 0;
                }
            }
        }
    }
    @include breakpoints(tablet-medium){
        .zigzag_block{
            .side_img_div{
                width: 42%;
            }
            .zigzag_content{
                max-width: 54%;
            }
            &:nth-child(even){
                .zigzag_content{
                    max-width: 54%;
                }
            }
        }
    }
    @include breakpoints(small){
        .grid-container{
            padding: 0;
        }
        .zigzag_block{
            .side_img_div{
                width: 100%;
                position: relative;
                padding-top: 45%;
                background-position: center center;
            }
            .zigzag_content{
                max-width: 100%;
                padding: rem-calc(20 15 25);
                h2{
                    font-size: rem-calc(30);
                    line-height: rem-calc(38);
                    margin: rem-calc(0 0 10 0);
                }
                h3{
                    font-size: rem-calc(22);
                    line-height: rem-calc(28);
                    margin: rem-calc(0 0 10 0);
                }
                p{
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                    margin: rem-calc(0 0 20 0);
                }
            }
            &:nth-child(even){
                .zigzag_content{
                    max-width: 100%;
                    padding: rem-calc(20 15 30);
                }
            }
        }
    }
}


.services{
    .page_content_sec{
        + .zigzag_main .zigzag_block{
            .zigzag_content{
                h2{
                    font-size: rem-calc(30);
                    line-height: rem-calc(36);
                    font-weight: $bold;
                    color: $white;
                    letter-spacing: rem-calc(1.6);
                    margin: rem-calc(0 0 14 0);
                }
                p{
                    font-size: rem-calc(18);
                    line-height: rem-calc(24);
                    font-weight: $regural;
                    color: $white;
                    margin: rem-calc(0 0 27 0);

                }
            }
            &.blue{
                h2{
                    color: $orange;
                }
                p{
                    color: $white;
                }
            }
            &.teal{
                h2{
                    color: $white;
                }
                p{
                    color: $white;
                }
            }
            &.gray{
                h2{
                    color: $blue;
                }
                p{
                    color: $blue;
                }
            }
        }
    }
}