.description_block_sec{
    .cell{
        padding: rem-calc(0 30 75);
    }
    .grid-x{
        margin: rem-calc(0 -30);
    }
    .description_block_wrap{
        max-width: 74%;
        padding: rem-calc(0 0 0 45);
        position: relative;
        .description_block_content{
            .primary_button{
                margin: rem-calc(0 0 14 0);
                border-color: $teal;
                background-color: $teal;
                &:hover{
                    border-color: $orange;
                    background-color: $orange;
                }
            }
            p{
                padding: rem-calc(0 0 0 32);
            }
        }
    }
    @include breakpoints(tablet-portrait){
        .cell{
            padding: rem-calc(0 15 75);
        }
        .grid-x{
            margin: rem-calc(0 -15);
        }
        .description_block_wrap{
            max-width: 100%;
            padding: rem-calc(0 0 0 55);
        }
    }
    @include breakpoints(small){
        .cell{
            padding: rem-calc(0 15 40);
        }
        .description_block_wrap{
            .description_block_content{
                p{
                    padding: 0;
                }
            }
        }
    }
}