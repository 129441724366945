.support_table_sec{
    background-color: $gray;
    .support_table_main{
        max-width: rem-calc(1020);
        margin: 0 auto;
        padding: rem-calc(5 0 94 105);
        table{
            margin: 0;
            tbody{
                background-color: transparent;
                border: 0;
                tr{
                    background-color: transparent;
                    td{
                        border-bottom: rem-calc(1) solid $teal;
                        border-left: rem-calc(1) solid $teal;
                        padding: rem-calc(18 15 12 15);
                        &:first-child{
                            border-left: 0;
                        }
                        h6{
                            font-size: rem-calc(20);
                            line-height: rem-calc(24);
                            font-weight: $bold;
                            margin: rem-calc(0 0 0 0);
                            letter-spacing: rem-calc(1);
                        }
                        span{
                            font-size: rem-calc(18);
                            line-height: rem-calc(22);
                            font-weight: $bold;
                            letter-spacing: rem-calc(1);
                        }
                        p{
                            font-size: rem-calc(18);
                            line-height: rem-calc(22);
                            font-weight: $regural;
                            color: $blue;
                        }
                        ul{
                            margin: rem-calc(0 0 0 0);
                            li{
                                font-size: rem-calc(18);
                                line-height: rem-calc(22);
                                font-weight: $regural;
                                color: $blue;
                                letter-spacing: rem-calc(0.9);
                                padding: rem-calc(0 0 2 14);
                                &:before{
                                    width: rem-calc(9);
                                    height: rem-calc(9);
                                    top: rem-calc(9);
                                    left: 0;
                                    background-image: url(../../../src/assets/images/plus-icon.png);
                                }
                            }
                        }
                        &:first-child{
                            width: 33.7%;
                        }
                        &:last-child{
                            width: 52%;
                        }
                    }
                    &:first-child{
                        td{
                            padding: rem-calc(19 15 12 15);
                        }
                    }
                }
            }
        }
    }
    @include breakpoints(small){
        .support_table_main{
            padding: rem-calc(5 0 94 0);
            overflow: auto;
            table{
                width: rem-calc(767);
            }
        }
    }
}