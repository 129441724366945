.accordion_sec{
    background-color: $gray;
    padding: rem-calc(123 0 95 0);
    .accordion_sec_wrap{
        margin-left: auto;
        .accordion_box{
            position: relative;
            overflow: hidden;
            margin: rem-calc(0 0 61 0);
            padding: rem-calc(0 0 0 74);
            display: flex;
            flex-wrap: wrap;
            .accordion_title{
                border-top: rem-calc(1) solid $blue;
                padding: rem-calc(39 65 0 1);
                padding-bottom: rem-calc(80);
                /*max-width: 54.5%;*/
                cursor: pointer;
                @include transition(padding, .5s, ease);
                h3{
                    font-size: rem-calc(25);
                    line-height: rem-calc(30);
                    font-weight: $bold;
                    color: $lightorange;
                    margin: rem-calc(0 0 10 0);
                    letter-spacing: rem-calc(1.3);
                }
                p{
                    font-size: rem-calc(25);
                    line-height: rem-calc(35);
                    font-weight: $medium;
                    font-style: italic;
                    color: $blue;
                    letter-spacing: rem-calc(1.3);
                }
                .more_text{
                    font-size: rem-calc(20);
                    line-height: rem-calc(22);
                    font-weight: $medium;
                    color: $lightorange;
                    display: inline-block;
                    padding: rem-calc(20 0 0 0);
                }
                .accordion_arrow{
                    position: absolute;
                    left: 0;
                    top: rem-calc(31);
                    width: rem-calc(60);
                    height: rem-calc(60);
                    background-color: $blue;
                    border-radius: 50%;
                    &:before{
                        position: absolute;
                        content: "";
                        width: rem-calc(20);
                        height: rem-calc(2);
                        background-color: $orange;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                    }
                    &:after{
                        position: absolute;
                        content: "";
                        width: rem-calc(2);
                        height: rem-calc(20);
                        background-color: $orange;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        @include transition(.5s, all, ease);
                    }
                }
                &.active{
                    padding-bottom: rem-calc(10);
                    .accordion_arrow{
                        &:after{
                            transform: rotate(-90deg);
                        }
                    }
                    .more_text{
                        display: none;
                    }
                }
            }
            .accordion_content{
                padding: rem-calc(0 70 0 1);
                /*max-width: 54.5%;*/
                p{
                    font-size: rem-calc(18);
                    line-height: rem-calc(25);
                    font-weight: $regural;
                    color: $blue;
                    margin: rem-calc(0 0 14 0);
                }
            }
            .accordion_left{
                max-width: 54.5%;
            }
            .accordion_right_content{
                width: 45.5%;
                background-color: $blue;
                padding: rem-calc(40 40 78 30);
                h3{
                    font-size: rem-calc(25);
                    line-height: rem-calc(33);
                    font-weight: $bold;
                    color: $orange;
                    margin: rem-calc(0 0 6 0);
                    letter-spacing: rem-calc(1.3);
                }
                ul{
                    margin: rem-calc(0);
                }
                a{
                    font-weight: $regural;
                    font-size: rem-calc(18);
                    line-height: rem-calc(25);
                    color: $white;
                    margin: rem-calc(18 0 0 24);
                    display: inline-block;
                    &:hover{
                        color: $orange;
                    }
                }
                .view_featured{
                    a{
                        margin: rem-calc(18 0 0 0);
                    }
                }
                &.active{
                    .view_featured{
                        display: none;
                    }
                }
            }
        }
    }
    @include breakpoints(small){
        padding: rem-calc(50 0 20 0);
        .accordion_sec_wrap{
            .accordion_box{
                margin: rem-calc(0 0 0 0);
                padding: rem-calc(0 0 30 74);
                .accordion_left{
                    width: 100%;
                    max-width: 100%;
                }
                .accordion_title{
                    padding: rem-calc(30 0 0 0);
                    padding-bottom: rem-calc(10);
                    max-width: 100%;
                }
                .accordion_content{
                    padding: rem-calc(0 0 0 0);
                    max-width: 100%;
                }
                .accordion_right_content{
                    position: static;
                    width: 100%;
                    margin: rem-calc(20 0 0 0);
                    padding: rem-calc(20 30 20 30);
                    .featured_content{
                        display: block !important;
                    }
                }
            }
        }
    }
    @include breakpoints(x-small){
        .accordion_sec_wrap{
            .accordion_box{
                padding: rem-calc(0 0 30 50);
                .accordion_title{
                    padding: rem-calc(15 0 0 0);
                    padding-bottom: rem-calc(10);
                    h3{
                        font-size: rem-calc(22);
                        line-height: rem-calc(28);
                        letter-spacing: rem-calc(1);
                    }
                    p{
                        font-size: rem-calc(20);
                        line-height: rem-calc(26);
                        letter-spacing: rem-calc(0.7);
                    }
                    .accordion_arrow{
                        width: rem-calc(36);
                        height: rem-calc(36);
                        top: rem-calc(20);
                        &:before{
                            width: rem-calc(16);
                        }
                        &:after{
                            height: rem-calc(16);
                        }
                    }
                }
            }
        }
    }
}