.page_content_sec{
    background-image: url(../../../src/assets/images/rebel.png);
    position: relative;
    /*&:before{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.92) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 );
    }*/
    .page_content_wrap{
        position: relative;
        padding: rem-calc(170 0 75 0);
        .editor_content{
            padding: rem-calc(0 0 0 75);
            max-width: 76%;
            p{
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                font-weight: $regural;
                color: $white;
                margin: rem-calc(0 0 20 0);

                strong{
                    font-weight: $bold;
                    color: $orange;
                }
                a{
                    color: $teal;
                    border-bottom: rem-calc(1) solid $teal;
                    &:hover{
                        color: $orange;
                        border-color: $orange;
                    }
                }
                &:first-of-type{
                    position: relative;
                    &:before{
                        position: absolute;
                        content: "";
                        left: rem-calc(-75);
                        top: rem-calc(7);
                        width: rem-calc(45);
                        height: rem-calc(20);
                        background-image: url(../../../src/assets/images/teal-arrow.svg);
                        background-repeat: no-repeat;
                        background-position: 0 0;
                    }
                }
            }
        }
    }
    @include breakpoints(tablet-portrait){
        .page_content_wrap{
            padding: rem-calc(80 0 80 0);
            .editor_content{
                max-width: 100%;
            }
        }
    }
    @include breakpoints(small){
        .page_content_wrap{
            padding: rem-calc(30 0 20 0);
            .editor_content{
                padding: rem-calc(0 0 0 55);
                p{
                    font-size: rem-calc(18);
                    line-height: rem-calc(24);
                    &:first-of-type{
                        &:before{
                            left: rem-calc(-55);
                        }
                    }
                }
            }
        }
    }
}

.contact {
    .page_content_sec{
        &:before{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.92) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 );
        }
    }
}