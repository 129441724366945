.site-header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    padding: rem-calc(67 0 0 0);
    @include transition(.5s, padding, ease-in-out);
    .header_inner{
        position: relative;
        text-align: right;
        .logo{
            position: absolute;
            left: 0;
            top: rem-calc(-7);
            z-index: 1;
            width: rem-calc(240);
            @include transition(.5s, all, ease);
        }
        .main_nav{
            display: inline-block;
        }
    }
    @include breakpoints(small){
        padding: rem-calc(10 0 10 0);
        .header_inner{
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: static;
            .logo{
                position: static;
                width: rem-calc(200);
            }
        }
    }
    @include breakpoints(extra-small){
        .header_inner{
            .logo{
                width: rem-calc(150);
            }
        }
    }
}

.menu-open{
    .site-header{
        .header_inner{
            .logo{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

.sticky-header{
    .site-header{
        /*background-color: $blackcolor;*/
        padding: rem-calc(20 0 12 0);
        .header_inner{
            .logo{
                width: rem-calc(120);
                top: rem-calc(-12);
                display: none;
            }
        }
        .menu-icon{
            .menu-box{
                border-color: $orange;
                background-color: $orange;
            }
        }
    }
    &.menu-open{
        .site-header{
            .header_inner{
                .logo{
                    display: none;
                }
            }
        }
        @include breakpoints(min-tablet){
            .site-header{
                .header_inner{
                    .logo{
                        /*top: rem-calc(-12);*/
                    }
                }
            }
            .menu-box{
                top: 0;
            }
        }
    }
    @include breakpoints(small){
        .site-header{
            padding: rem-calc(10 0 10 0);
        }
    }
}

.menu-open{
    @include breakpoints(min-tablet){
        .site-header{
            .header_inner{
                .logo{
                    top: rem-calc(-42);
                }
            }
        }
        .menu-box{
            top: rem-calc(-33);
        }
    }
}