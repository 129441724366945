.why_airship_sec{
    background-image: url(../../../src/assets/images/rebel.png);
    position: relative;
    .why_airship_main{
        position: relative;
        padding: rem-calc(33 0 0 0);
        .why_airship_heading{
            position: relative;
            padding: rem-calc(158 20 80 76);
            &:before{
                position: absolute;
                content: "";
                top: 0;
                left: rem-calc(-43);
                right: 0;
                bottom: 0;
                background-image: url(../../../src/assets/images/ship-icon.png);
                background-repeat: no-repeat;
                background-position: 0 0;
            }
            h2{
                font-size: rem-calc(50);
                line-height: rem-calc(60);
                font-weight: $black;
                color: $white;
                text-transform: uppercase;
                letter-spacing: rem-calc(3.3);
                position: relative;
            }
        }
        .airship_block_list{
            padding: rem-calc(46 0 45 0);
            .airship_block_content{
                margin: rem-calc(0 0 79 0);
                h3{
                    font-size: rem-calc(30);
                    line-height: rem-calc(36);
                    font-weight: $black;
                    color: $orange;
                    letter-spacing: rem-calc(0.3);
                    margin: rem-calc(0 0 14 0);
                }
                p{
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                    font-weight: $regural;
                    color: $white;
                }
            }
        }
    }
    @include breakpoints(max-x-large){
        .why_airship_main{
            .why_airship_heading{
                /*padding: rem-calc(120 20 115 76);*/
                &:before{
                    left: 0;
                }
            }
        }
    }
    @include breakpoints(small){
        .why_airship_main{
            .why_airship_heading{
                padding: rem-calc(0 0 0 0);
                &:before{
                    background-size: contain;
                }
                h2{
                    font-size: rem-calc(32);
                    line-height: rem-calc(42);
                    padding: rem-calc(50 0 40 20);
                }
            }
            .airship_block_list{
                .airship_block_content{
                    margin: rem-calc(0 0 30 0);
                }
            }
        }
    }
    @include breakpoints(x-small){
        .why_airship_main{

            .airship_block_list{
                padding: rem-calc(20 0 30 0);
                .airship_block_content{
                    h3{
                        font-size: rem-calc(24);
                        line-height: rem-calc(30);
                        margin: rem-calc(0 0 10 0);
                    }
                    p{
                        font-size: rem-calc(18);
                        line-height: rem-calc(26);
                    }
                }
            }
        }
    }
}