.order_list_sec{
    background-color: $gray;
    padding: rem-calc(105 0 0 0);
    .order_list_wrap{
        .order_list_heading{
            max-width: 74%;
            h2{
                font-size: rem-calc(40);
                line-height: rem-calc(48);
                font-weight: $bold;
                color: $teal;
                margin: rem-calc(0 0 28 0);
                letter-spacing: rem-calc(1.95);
            }
            p{
                font-size: rem-calc(25);
                line-height: rem-calc(34);
                font-weight: $light;
                color: $blue;
                letter-spacing: rem-calc(1.24);
            }
        }
        .order_list_main{
            max-width: rem-calc(1010);
            margin: 0 auto;
            padding: rem-calc(73 0 0 4);
            .order_list_box{
                ol{
                    margin: rem-calc(0 0 0 0);
                    counter-reset: my-sec-counter;
                    li{
                        list-style: none;
                        position: relative;
                        padding: rem-calc(9 0 66 96);
                        &:before{
                            position: absolute;
                            content: counter(my-sec-counter);
                            counter-increment: my-sec-counter;
                            width: rem-calc(60);
                            height: rem-calc(60);
                            border-radius: 50%;
                            background-color: $teal;
                            left: rem-calc(6);
                            top: 0;
                            font-size: rem-calc(30);
                            line-height: rem-calc(57);
                            font-weight: $bold;
                            color: $white;
                            text-align: center;
                        }
                        h3{
                            font-size: rem-calc(30);
                            line-height: rem-calc(38);
                            font-weight: $light;
                            color: $blue;
                            margin: rem-calc(0 0 12 0);
                            letter-spacing: rem-calc(1.4);
                            strong{
                                font-weight: $bold;
                                color: $lightorange;
                            }
                        }
                        p{
                            font-size: rem-calc(18);
                            line-height: rem-calc(25);
                            font-weight: $regural;
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
    @include breakpoints(tablet-portrait){
        .order_list_wrap{
            .order_list_heading{
                max-width: 100%;
            }
        }
    }
    @include breakpoints(small){
        padding: rem-calc(30 0 0 0);
        .order_list_wrap {
            .order_list_heading{
                h2{
                    font-size: rem-calc(30);
                    line-height: rem-calc(36);
                    margin: rem-calc(0 0 15 0);
                    letter-spacing: rem-calc(1);
                }
                p{
                    font-size: rem-calc(22);
                    line-height: rem-calc(26);
                }
            }
            .order_list_main{
                padding: rem-calc(40 0 0 0);
                .order_list_box{
                    ol{
                        li{
                            padding: rem-calc(65 0 40 0);
                            &:before{
                                left: rem-calc(0);
                            }
                            h3{
                                font-size: rem-calc(28);
                                line-height: rem-calc(34);
                                margin: rem-calc(0 0 8 0);
                            }
                        }
                    }
                }
            }
        }
    }
    @include breakpoints(extra-small){
        .order_list_wrap{
            .order_list_main{
                .order_list_box{
                    ol{
                        li{
                            h3{
                                font-size: rem-calc(24);
                                line-height: rem-calc(28);
                            }
                        }
                    }
                }
            }
        }
    }
}