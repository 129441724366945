.footer_main{
    background-repeat: no-repeat;
    background-position: right bottom;
    background-attachment: fixed;
    position: relative;
    background-size: cover;
    &.overlay{
        .footer_top_sec{
            &:before{
                position: absolute;
                content: "";
                background: -moz-linear-gradient(top, rgba(1,1,1,0.85) 0%, rgba(0,0,0,0) 100%);
                background: -webkit-linear-gradient(top, rgba(1,1,1,0.85) 0%,rgba(0,0,0,0) 100%);
                background: linear-gradient(to bottom, rgba(1,1,1,0.85) 0%,rgba(0,0,0,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9010101', endColorstr='#00ffffff',GradientType=0 );
                /*background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.92) 100%);
                background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 );*/
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
    &.hide_gradient{
        .footer_top_sec{
            &:before{
                display: none;
            }
        }
    }
    .footer_top_sec{
        background-image: url(../../../src/assets/images/rebel.png);
        position: relative;
        .footer_form_sec{
            position: relative;
            z-index: 1;
            .form_heading{
                text-align: center;
                h2{
                    font-size: rem-calc(50);
                    line-height: rem-calc(62);
                    font-weight: $black;
                    color: $white;
                    letter-spacing: rem-calc(3.5);
                    margin: rem-calc(0 0 13 0);
                }
                p{
                    font-size: rem-calc(25);
                    line-height: rem-calc(33);
                    font-weight: $light;
                    color: $white;
                }
            }
            .footer_top_content{
                padding: rem-calc(148 0 138 0);
                text-align: center;
                max-width: rem-calc(815);
                margin: 0 auto;
                h2{
                    font-size: rem-calc(45);
                    line-height: rem-calc(54);
                    font-weight: $black;
                    color: $white;
                    letter-spacing: rem-calc(2.9);
                    margin: rem-calc(0 0 14 0);
                    text-transform: uppercase;
                }
                p{
                    font-size: rem-calc(25);
                    line-height: rem-calc(40);
                    font-weight: $light;
                    color: $white;
                    margin: rem-calc(0 0 40 0);
                }
            }
            .support_form_sec{
                max-width: rem-calc(650);
                margin: 0 auto;
                padding: rem-calc(84 0 130 0);
                .form_heading{
                    margin: rem-calc(0 0 76 0);
                }
            }
            .careers_form_sec{
                max-width: rem-calc(910);
                margin: 0 auto;
                padding: rem-calc(136 0 110 0);
                .form_main{
                    max-width: rem-calc(650);
                    margin: 0 auto;
                }
                .form_heading{
                    margin: rem-calc(0 0 87 0);
                }
            }
            .contact_form_sec{
                padding: rem-calc(45 0 135 81);
                .form_heading{
                    text-align: left;
                    max-width: 60%;
                    padding: rem-calc(0 0 46 0);
                    h2{
                        font-size: rem-calc(40);
                        line-height: rem-calc(48);
                        font-weight: $black;
                        color: $teal;
                        letter-spacing: rem-calc(1.8);
                        margin: rem-calc(0 0 12 0);
                        text-transform: uppercase;
                    }
                    p{
                        font-size: rem-calc(20);
                        line-height: rem-calc(28);
                        font-weight: $regural;
                        color: $white;
                    }
                }
                .contact_form_wrap{
                    display: flex;
                    flex-wrap: wrap;
                    .form_main{
                        width: 45.2%;
                        .gform_wrapper .gform_footer{
                            text-align: left;
                            padding: rem-calc(0 0 0 0);
                        }
                    }
                    .contact_info{
                        padding: rem-calc(109 0 0 105);
                        width: 53%;
                        h6{
                            font-size: rem-calc(20);
                            line-height: rem-calc(24);
                            font-weight: $bold;
                            color: $orange;
                            margin: rem-calc(0 0 1 0);
                        }
                        p{
                            font-size: rem-calc(20);
                            line-height: rem-calc(28);
                            font-weight: $regural;
                            color: $white;
                            strong{
                                font-weight: $bold;
                            }
                            a{
                                &:hover{
                                    color: $orange;
                                }
                                &:focus{
                                    color: $orange;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .footer_bottom_sec{
        position: relative;
        &:before{
            position: absolute;
            content: "";
            background-image: url(../../../src/assets/images/footer-shape-bg.png);
            left: 0;
            bottom: 0;
            right: 0;
            top: rem-calc(-2);
            z-index: 1;
            background-position: center top;
            background-repeat: no-repeat;
        }
        &:after{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(to bottom, rgba(30,87,153,0) 0%,rgba(0,0,0,0.5) 100%);
            background: -moz-linear-gradient(top, rgba(30,87,153,0) 0%, rgba(0,0,0,0.5) 100%);
            background: -webkit-linear-gradient(top, rgba(30,87,153,0) 0%,rgba(0,0,0,0.5) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#80000000',GradientType=0 );
        }
        .footer_info_main{
            padding: 41.8% 0 4.6% 0;
            text-align: center;
            position: relative;
            z-index: 1;
            .footer_menu{
                ul{
                    margin: 0;
                    li{
                        display: inline-block;
                        padding: rem-calc(0 13);
                        &:before{
                            display: none;
                        }
                        a{
                            font-size: rem-calc(16);
                            line-height: rem-calc(19);
                            font-weight: $bold;
                            color: $off_white;
                            text-transform: uppercase;
                            text-decoration: none;
                            letter-spacing: rem-calc(0.3);
                            &:hover{
                                color: $orange;
                            }
                            &:focus{
                                color: $orange;
                            }
                        }
                        &.current-menu-item{
                            a{
                                color: $orange;
                            }
                        }
                    }
                }
            }
            .copy_sec{
                padding: rem-calc(21 0 0 0);
                p{
                    font-size: rem-calc(16);
                    line-height: rem-calc(19);
                    font-weight: $regural;
                    color: $dark_gray;
                    a{
                        padding: rem-calc(0 0 0 11);
                        margin: rem-calc(0 0 0 4);
                        border-left: rem-calc(1) solid $dark_gray;
                        line-height: rem-calc(16);
                        display: inline-block;
                        &:hover{
                            color: $orange;
                        }
                        &:focus{
                            color: $orange;
                        }
                    }
                }
            }
        }
    }
    @include breakpoints(tablet-portrait){
        .footer_top_sec{
            .footer_form_sec{
                .contact_form_sec{
                    padding: rem-calc(0 0 80 0);
                    .form_heading{
                        max-width: 100%;
                    }
                    .contact_form_wrap{
                        .form_main{
                            width: 55%;
                        }
                        .contact_info{
                            width: 44%;
                            padding: rem-calc(100 0 0 60);
                            p{
                                br{
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include breakpoints(small){
        .footer_top_sec{
            .footer_form_sec{
                .footer_top_content{
                    padding: rem-calc(60 0 20 0);
                    h2{
                        font-size: rem-calc(30);
                        line-height: rem-calc(38);
                        margin: rem-calc(0 0 10 0);
                    }
                    p{
                        font-size: rem-calc(18);
                        line-height: rem-calc(24);
                        margin: rem-calc(0 0 30 0);
                    }
                }
                .support_form_sec{
                    padding: rem-calc(30 0 0 0);
                    .form_heading{
                        margin: rem-calc(0 0 40 0);
                    }
                }
                .careers_form_sec{
                    padding: rem-calc(30 0 0 0);
                    .form_heading{
                        margin: rem-calc(0 0 40 0);
                    }
                }
                .contact_form_sec{
                    padding: rem-calc(30 0 20 0);
                    .form_heading{
                        padding: rem-calc(0 0 30 0);
                        h2{
                            font-size: rem-calc(30);
                            line-height: rem-calc(38);
                            letter-spacing: rem-calc(1.2);
                            margin: rem-calc(0 0 8 0);
                        }
                        p{
                            font-size: rem-calc(18);
                            line-height: rem-calc(26);
                        }
                    }
                    .contact_form_wrap{
                        .form_main{
                            width: 100%;
                        }
                        .contact_info{
                            width: 100%;
                            padding: rem-calc(40 0 0 0);
                        }
                    }
                }
                .form_heading{
                    h2{
                        font-size: rem-calc(30);
                        line-height: rem-calc(38);
                        letter-spacing: rem-calc(1.5);
                        margin: rem-calc(0 0 8 0);
                    }
                    p{
                        font-size: rem-calc(20);
                        line-height: rem-calc(26);
                    }
                }
            }
        }
        .footer_bottom_sec{
            .footer_info_main{
                .footer_menu{
                    ul{
                        li{
                            width: 100%;
                            padding: rem-calc(10 0);
                        }
                    }
                }
                .copy_sec{
                    p{
                        color: $white;
                        a{
                            color: $white;
                            border-color: $white;
                        }
                    }
                }
            }
        }

    }
    @include breakpoints(extra-small){
        .footer_bottom_sec{
            .footer_info_main{
                .copy_sec{
                    p{
                        a{
                            display: block;
                            padding: 0;
                            margin: rem-calc(10 0);
                            border: 0;
                        }
                    }
                }
            }
        }
    }
}