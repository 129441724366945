.main_banner_sec{
    position: relative;
    &:before{
        position: absolute;
        content: "";
        background-image: url(../../../src/assets/images/banner-shape-bg.png);
        left: 0;
        bottom: rem-calc(-2);
        right: 0;
        top: 0;
        z-index: 1;
        background-position: center bottom;
        background-repeat: no-repeat;
    }
    &:after{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $blackcolor;
        opacity: 0.6;
    }
    .main_banner_div{
        height: rem-calc(765);
        position: relative;
        width: 100%;
        overflow: hidden;
        .banner_video {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            &:before{
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: -moz-linear-gradient(top, rgba(0,0,0,0.45) 0%, rgba(125,185,232,0) 100%);
                background: -webkit-linear-gradient(top, rgba(0,0,0,0.45) 0%,rgba(125,185,232,0) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0.45) 0%,rgba(125,185,232,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#73000000', endColorstr='#007db9e8',GradientType=0 );
            }
            .slider-video {
                height: 100%;
                object-fit: cover;
                width: 100%;
                background-size: cover;
            }
        }
        .banner_content{
            position: relative;
            z-index: 1;
            max-width: rem-calc(930);
            margin: 0 auto;
            text-align: center;
            padding: rem-calc(250 0 0 0);
            .page_title{
                font-size: rem-calc(50);
                line-height: rem-calc(60);
                font-weight: $black;
                color: $white;
                text-transform: uppercase;
                letter-spacing: rem-calc(1.6);
                margin: rem-calc(0 0 7 0);
            }
            h1{
                font-size: rem-calc(30);
                line-height: rem-calc(36);
                font-weight: $bold;
                color: $orange;
                letter-spacing: rem-calc(1.2);
                margin: rem-calc(0 0 24 0);
            }
            p{
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                font-weight: $regural;
                color: $white;
                margin: rem-calc(0 0 40 0);
            }
            .primary_button{
                margin: rem-calc(0 10 15);
                &.teal_button{
                    background-color: $teal;
                    border-color: $teal;
                    &:hover{
                        background-color: $orange;
                        border-color: $orange;
                    }
                    &:focus{
                        background-color: $orange;
                        border-color: $orange;
                    }
                }
            }
        }
    }
    @include breakpoints(double-extra-large){
        .main_banner_div{
            height: rem-calc(900);
        }
    }
    @include breakpoints(tablet-portrait){
        .main_banner_div{
            height: auto;
            .banner_content{
                padding: rem-calc(250 0 150);
                .page_title{
                    font-size: rem-calc(34);
                    line-height: rem-calc(45);
                }
                h1{
                    font-size: rem-calc(22);
                    line-height: rem-calc(30);
                    margin: rem-calc(0 0 20 0);
                }
                p{
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                    margin: rem-calc(0 0 30 0);
                }
            }
        }
    }
    @include breakpoints(small){
        .main_banner_div{
            .banner_content{
                padding: rem-calc(130 0 100);
                .page_title{
                    font-size: rem-calc(30);
                    line-height: rem-calc(42);
                }
            }
        }
    }
}

@media all and (-ms-high-contrast:none){
    .main_banner_sec .main_banner_div .banner_video .slider-video{height: auto;}        
}

_:-ms-lang(x), .main_banner_sec .main_banner_div .banner_video .slider-video{height: auto;}

