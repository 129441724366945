.news_insights_sec{
    .news_insights_wrap{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        .news_insights_left{
            width: 42%;
            text-align: right;
        }
        .news_insights_right{
            width: 58%;
            padding: rem-calc(36 0 0 0);
            .news_insights_box{
                display: flex;
                flex-wrap: wrap;
                padding: rem-calc(0 0 79 0);
                .news_insights_date{
                    width: 18.5%;
                    span{
                        font-size: rem-calc(30);
                        line-height: rem-calc(36);
                        font-weight: $light;
                        color: $dark_gray;
                    }
                }
                .news_insights_link{
                    width: 81%;
                    padding: rem-calc(2 90 0 0);
                    a{
                        font-size: rem-calc(25);
                        line-height: rem-calc(33);
                        font-weight: $bold;
                        color: $orange;
                        &:hover{
                            color: $teal;
                        }
                    }
                }
            }
        }
        .load_more{
            text-align: center;
            width: 100%;
            a{
                font-size: rem-calc(30);
                line-height: rem-calc(36);
                font-weight: $light;
                color: $dark_gray;
                margin-left: rem-calc(220);
                text-transform: uppercase;
                letter-spacing: rem-calc(-0.2);
                &:hover{
                    color: $orange;
                }
                &:focus{
                    color: $orange;
                }
            }
        }
    }
    @include breakpoints(tablet-portrait){
        .news_insights_wrap{
            .news_insights_right{
                .news_insights_box{
                    .news_insights_date{
                        width: 22%;
                    }
                    .news_insights_link{
                        width: 78%;
                        padding: rem-calc(2 0 0 10);
                    }
                }
            }
        }
    }
    @include breakpoints(small){
        .news_insights_wrap{
            .news_insights_left{
                width: 100%;
                text-align: left;
            }
            .news_insights_right{
                width: 100%;
                .news_insights_box{
                    padding: rem-calc(0 0 30 0);
                    .news_insights_date{
                        width: 100%;
                    }
                    .news_insights_link{
                        width: 100%;
                        padding: rem-calc(10 0 0 0);
                        a{
                            font-size: rem-calc(22);
                            line-height: rem-calc(28);
                        }
                    }
                }
            }
            .load_more{
                a{
                    margin: rem-calc(20 0 0 0);
                    display: inline-block;
                }
            }
        }
    }
}