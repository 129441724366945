.resource_block_sec{
    .resource_block_wrap{
        padding: rem-calc(0 21 0 76);
        position: relative;
        .resource_block_content{
            padding: rem-calc(0 20 50 0);
            h2{
                font-size: rem-calc(30);
                line-height: rem-calc(36);
                font-weight: $bold;
                color: $orange;
                text-transform: uppercase;
                letter-spacing: rem-calc(1.3);
                margin: rem-calc(0 0 9 0);
            }
            ul{
                margin: 0;
                li{
                    padding: rem-calc(0 0 10 24);
                    &:before{
                        background-image: url(../../../src/assets/images/orange-arrow.png);
                        width: rem-calc(17);
                        height: rem-calc(13);
                        top: rem-calc(7);
                    }
                    a{
                        text-decoration: none;
                        &:hover{
                            color: $orange;
                        }
                        &:focus{
                            color: $orange;
                        }
                    }
                }
            }
            .primary_button{
                margin: rem-calc(6 0 0 0);
                background-color: $teal;
                border-color: $teal;
                padding: rem-calc(13 32 16 32);
                &:hover{
                    background-color: $orange;
                    border-color: $orange;
                }
            }
        }
    }
    @include breakpoints(tablet-portrait){
        .resource_block_wrap{
            padding: rem-calc(0 0 0 0);
        }
    }
    @include breakpoints(small){
        .resource_block_wrap{
            .resource_block_content{
                padding: rem-calc(0 0 40 0);
            }
        }
    }
    @include breakpoints(extra-small){
        .resource_block_wrap{
            .resource_block_content{
                h2{
                    font-size: rem-calc(24);
                    line-height: rem-calc(30);
                }
                .primary_button{
                    padding: rem-calc(10 15 13 15);
                    font-size: rem-calc(18);
                }
            }
        }
    }
}