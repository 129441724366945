body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: $barlow;
}
.grid-container{
    padding: rem-calc(0 15);
    max-width: rem-calc(1260);
}

.common_bg{
    background-image: url(../../../src/assets/images/rebel.png);
    position: relative;
    &:before{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.92) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 );
    }
}

.primary_button{
    font-size: rem-calc(20);
    line-height: rem-calc(24);
    font-weight: $black;
    color: $white;
    text-transform: uppercase;
    border: rem-calc(1) solid $orange;
    background-color: $orange;
    border-radius: rem-calc(22);
    display: inline-block;
    padding: rem-calc(13 26 15 29);
    letter-spacing: rem-calc(1.4);
    &:hover{
        background-color: $teal;
        border-color: $teal;
        color: $white;
    }
    &:focus{
        background-color: $teal;
        border-color: $teal;
        color: $white;
    }
}

.integration{
    .image_content_block{
        padding: rem-calc(0 0 100 0);
    }
    .image_content_block_sec{
        padding-top: 0;
    }
    .page_content_sec .page_content_wrap{
        padding-bottom: rem-calc(140);
    }
    @include breakpoints(small){
        .page_content_sec .page_content_wrap{
            padding-bottom: rem-calc(50);
        }
        .image_content_block{
            padding: rem-calc(0 0 30 0);
        }
    }
}



@include breakpoints(min-tablet){
    .products{
        .zigzag_main{
            .zigzag_block{
                .zigzag_content{
                    padding: rem-calc(149 0 149 0);
                }
            }
        }
    }
}

.government-vs-commercial{
    .image_content_block_sec{
        padding-top: 0;
    }
    .page_content_sec .page_content_wrap{
        padding-bottom: rem-calc(100);
    }
    @include breakpoints(small){
        .page_content_sec .page_content_wrap{
            padding-bottom: rem-calc(50);
        }
    }
}

