.inner_banner_sec{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    &:before{
        position: absolute;
        content: "";
        background-image: url(../../../src/assets/images/banner-shape-bg.png);
        left: 0;
        bottom: rem-calc(-2);
        right: 0;
        top: 0;
        z-index: 1;
        background-position: center bottom;
        background-repeat: no-repeat;
    }
    .inner_banner_wrap{
        position: relative;
        z-index: 1;
        .inner_banner_content{
            max-width: 63%;
            padding: rem-calc(250 0 285 76);
            .page_title{
                font-size: rem-calc(25);
                line-height: rem-calc(30);
                font-weight: $black;
                color: $orange;
                text-transform: uppercase;
                padding: rem-calc(0 0 10 0);
                margin: rem-calc(0 0 11 0);
                letter-spacing: rem-calc(1.1);
                border-bottom: rem-calc(1) solid $teal;
                display: inline-block;
            }
            h1{
                font-size: rem-calc(45);
                line-height: rem-calc(58);
                font-weight: $bold;
                color: $white;
                letter-spacing: rem-calc(0.3);
                margin: rem-calc(0);
            }
            @include breakpoints(double-extra-large){
                padding-bottom: rem-calc(425);
            }
            .scroll_down{
                padding: rem-calc(30 0 15 0);
                a{
                    display: inline-block;
                    vertical-align: top;
                    background-image: url(../../../src/assets/images/down-arrow.svg);
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: rem-calc(35);
                    height: rem-calc(35);
                    
                }
            }
        }
    }
    @include breakpoints(extremly-large){
        .inner_banner_wrap{
            .inner_banner_content{
                h1{
                    br{
                        display: none;
                    }
                }
            }
        }
    }
    @include breakpoints(tablet-portrait){
        .inner_banner_wrap{
            .inner_banner_content{
                max-width: 100%;
                padding: rem-calc(250 0 285 0);
            }
        }
    }
    @include breakpoints(small){
        .inner_banner_wrap{
            .inner_banner_content{
                padding: rem-calc(150 0 150 0);
                h1{
                    font-size: rem-calc(30);
                    line-height: rem-calc(38);
                }
            }
        }
    }
}