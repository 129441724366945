/*
Only required is to display none the .menu-icon
*/

.menu-icon {
    display: none;
    cursor: pointer;
    padding: 0;
    height: auto;
    width: auto;
    font-size: 0px;
}
.menu-icon::after{display: none;}
.menu-icon .menu-box {
    width: rem-calc(50);
    margin-right: 0;
    padding: rem-calc(9);
    display: inline-block;
    border: rem-calc(2) solid $white;
    position: relative;
    top: 0;
    @include transition(.5s, all, ease);
}
.menu-icon .menu-box span {
    width: 100%;
    height: 2px;
    margin-bottom: 7px;
    display: block;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    background-color: $white;
    &:last-child{
        margin-bottom: 0;
    }
}
.menu-icon.active span:nth-child(1) {
    transform: rotate(45deg) translateY(8px) translateX(6px);
    -webkit-transform: rotate(45deg) translateY(8px) translateX(6px);
}
/*.menu-icon.hover:not(.active) span:nth-child(1) {
transform: rotate(90deg) translateX(8px);
-webkit-transform: rotate(90deg) translateX(8px);
}*/
.menu-icon.active span:nth-child(2) {
    opacity: 0;
}
.menu-icon.active span:nth-child(3) {
    transform: rotate(-45deg) translateY(-7px) translateX(6px);
    -webkit-transform: rotate(-45deg) translateY(-7px) translateX(6px);
}
/*.menu-icon.hover:not(.active) span:nth-child(3) {
transform: rotate(-90deg) translateX(6px);
-webkit-transform: rotate(-90deg)  translateX(6px);
}*/
.enumenu_ul .caret {
    color: #fff;
    margin-left: 7px;
}
.enumenu_ul {
    padding: 0px;
    list-style: none;
}

.enumenu_ul li:before{display: none;}
.enumenu_ul > li ul {
    display: none
}
.enumenu_ul ul {margin: 0;}
.enumenu_ul ul li {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    list-style: none;
    position: relative;
    padding: 0;
}
.enumenu_ul ul.menu_main > li:nth-child(1){width: 20.9%;}
.enumenu_ul ul.menu_main > li:nth-child(2){width: 20%;}
.enumenu_ul ul.menu_main > li:nth-child(3){width: 18.85%;}
.enumenu_ul ul.menu_main > li:nth-child(4){width: 21.14%;}
.enumenu_ul ul.menu_main > li:nth-child(5){width: 18.7%;}
.enumenu_ul ul li .sub-menu{
    display: block !important;
    padding: rem-calc(9 0 0 0);
}

@include breakpoints(small){
    .enumenu_ul ul.menu_main{flex-wrap: wrap;}
    .enumenu_ul ul.menu_main > li:nth-child(1){width: 100%;padding: rem-calc(0 0 30 0);}
    .enumenu_ul ul.menu_main > li:nth-child(2){width: 100%;padding: rem-calc(0 0 30 0);}
    .enumenu_ul ul.menu_main > li:nth-child(3){width: 100%;padding: rem-calc(0 0 30 0);}
    .enumenu_ul ul.menu_main > li:nth-child(4){width: 100%;padding: rem-calc(0 0 30 0);}
    .enumenu_ul ul.menu_main > li:nth-child(5){width: 100%;padding: rem-calc(0 0 30 0);}
}
/*.enumenu_ul ul li .sub-menu{
position: absolute;
left: 0;
top: 110%;
width: 300px;
background-color: $white;
opacity: 0;
visibility: hidden;
@include transition(all, 0.5s, ease-in-out);
@include breakpoints(tablet-portrait){
position: static;
transition: none;
opacity: 1;
visibility: visible;
width: 100%;
}
}*/
.enumenu_ul ul li:hover .sub-menu{
    top: 100%;
    opacity: 1;
    visibility: visible;
}
.enumenu_ul ul li .sub-menu li{
    margin: 0;
    line-height: normal;
    display: block;
    padding: rem-calc(0 0 11 20);
}
.enumenu_ul ul li .sub-menu li a{
    display: inline-block;
    color: $white;
    font-size: rem-calc(18);
    line-height: rem-calc(22);
    font-family: $barlow;
    font-weight: $light;
    @include transition(all, 0.5s, ease-in-out);
    text-decoration: none;
    letter-spacing: rem-calc(0.2);
    padding: 0;
    &:before{
        display: none;
    }
    &:hover{
        color: $orange;
    }
}
.enumenu_ul ul li .sub-menu li.current-menu-item a{
    color: $orange;
}
/*.enumenu_ul.desk ul {
top: 100%;
z-index: 999;
list-style: none;
left: 0px;
background: #000000;
position: absolute;
min-width: 150px;
}*/
/*Sub menu styles*/

.menu.submenu-ul-level1 li a {
    background: rgba(31, 26, 107, 0.8);
    color: #fff;
    padding-left: 90px;
}
.enumenu_ul.desk li:hover > ul {
    display: block;
    opacity: 1;
}
.enumenu_ul.desk .sb-menu .sb-menu {
    left: 100%;
    top: 0;
}
.enumenu_ul ul li a {
    display: inline-block;
    color: $white;
    font-size: rem-calc(20);
    line-height: rem-calc(24);
    padding: 0;
    text-transform: uppercase;
    font-family: $barlow;
    font-weight: $bold;
    @include transition(all, 0.5s, ease-in-out);
    text-decoration: none;
    letter-spacing: rem-calc(0.4);
    padding-left: rem-calc(18);
    position: relative;
    &:before{
        position: absolute;
        content: "";
        left: 0;
        top: rem-calc(5);
        border-top: rem-calc(6) solid transparent;
        border-left: rem-calc(12) solid $off_white;
        border-bottom: rem-calc(6) solid transparent;
        @include transition(.5s, all, ease);
    }
    &:hover{
        color: $white;
        &:before{
            border-left-color: $orange;
        }
    }
}
.enumenu_ul ul li.current_page_item a {
    color: $white;
    &:before{
        border-left-color: $orange;
    }
}
.enumenu_ul ul li.current_page_parent a {
    color: $white;
    &:before{
        border-left-color: $orange;
    }
}
.enumenu_ul ul li.blue_btn{
    margin: rem-calc(0 0 0 31);
    padding: rem-calc(13 0 11 0);
    @include breakpoints(large){
        margin: rem-calc(0 0 0 3);
    }
    @include breakpoints(tablet-portrait){
        margin: rem-calc(0 0 0 0);
        padding: 0;
    }
}
.enumenu_ul ul li.blue_btn a{
    background-color: $blue;
    font-weight: $bold;
    color: $white;
    padding: rem-calc(12 20 12 20);
    border: rem-calc(1) solid $blue;
    @include breakpoints(large){
        padding: rem-calc(12 10 12 10);
    }
    @include breakpoints(tablet-portrait){
        padding: rem-calc(12 15 12 15);
    }
}
.enumenu_ul ul li.blue_btn a:hover{
    background-color: transparent;
    color: $blue;
}
.menu .is-active > a{
    color: $blue;
    background-color: transparent;
}
.enumenu_ul .arrow {
    position: absolute;
    right: rem-calc(23);
    top: rem-calc(13);
    width: rem-calc(24);
    height: rem-calc(24);
    cursor: pointer;
    z-index: 999;
    display: none;
    &:before{
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: rem-calc(2);
        background-color: $white;
        margin: auto;
    }
    &:after{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: rem-calc(2);
        background-color: $white;
        @include transition(all, 0.5s, ease-in-out);
    }
    &.up{
        &:after{
            transform: rotate(-90deg);
        }
    }
}
.enumenu_ul ul.menu_main{
    display: flex;
    text-align: left;
}
/*@media (max-width: 991px) {*/
.enumenu_ul{
    position: fixed;
    left: 0;
    right: 0;
    top: -100%;
    background-color: $blackcolor;
    padding: rem-calc(100 0 80 0);
    @include transition(top, 0.5s, ease-in-out);
    @include breakpoints(small){
        padding: rem-calc(30 0 100 0);
        height: 100vh;
        overflow: scroll;
        position: absolute;
        top: 100%;
    }
}
.menu-open{
    .enumenu_ul{
        top: 0;
    }
    @include breakpoints(small){
        .enumenu_ul{
            top: 100%;
        }
        .site-header{
            background-color: $blackcolor;
        }
    }
}
.enumenu_ul .menu-main-menu-container{
    max-width: rem-calc(915);
    margin: 0 auto;
}
header {
    padding-top: 0;
}
.enumenu_ul {
    display: none
}
.menu-block {
    height: auto;
}
.menu-icon {
    display: block;
    z-index: 1;
}
.services-detail a.pba-detail {
    border-bottom: 2px solid #d3d9da;
}
.enumenu_ul > li {
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.enumenu_ul > li > a {
    display: block;
    text-align: center;
    padding: 8px
}
.enumenu_ul {
    width: 100%;
}
.enumenu_ul > li ul {
    background: #D1D1D1;
}
.enumenu_ul > li ul a {
    text-align: center;
    display: block;
    text-align: center;
    color: #000;
    padding: 5px 0;
}
/* Push Menu */
body.menuslide_push {
    position: relative;
}
body.menuslide_push.slidemenuLeft {
    left: 0;
    transition: left 0.5s;
    -webkit-transition: left 0.5s;
    -moz-transition: left 0.5s;
    -ms-transition: left 0.5s;
    -o-transition: left 0.5s;
}
body.menuslide_push.slidemenuRight {
    right: 0;
    transition: right 0.5s;
    -webkit-transition: right 0.5s;
    -moz-transition: right 0.5s;
    -ms-transition: right 0.5s;
    -o-transition: right 0.5s;
}
body.slidemenuRight .enumenu_ul {
    right: -300px;
}
body.slidemenuRight.menu-open .enumenu_ul {
    right: 0;
}
body.slidemenuLeft .enumenu_ul {
    left: -300px;
}
body.slidemenuLeft.menu-open .enumenu_ul {
    left: 0;
}
body.menuslide_push.slidemenuLeft.menu-open {
    left: 300px
}
body.menuslide_push.slidemenuRight.menu-open {
    right: 300px
}
body.menuslide_push .enumenu_ul,
body.menuOverlap .enumenu_ul {
    background: #000000 none repeat scroll 0 0;
    display: block;
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow: auto;
    position: fixed;
    transition: all 0.5s ease 0s;
    max-width: 300px;
    top: 0;
}
/*}*/