.managed_services_block{
    background-color: $gray;
    .managed_services_wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: rem-calc(112 0 95 0);
        .services_block_left {
            width: 15%;
            padding: rem-calc(9 0 0 0);
        }
        .services_block_right {
            width: 77%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: rem-calc(0 0 0 2);
            .service_box_half{
                width: 44.5%;
            }
        }
        .service_box_full{
            margin: rem-calc(0 0 60 0);
            .service_content{
                p{
                    font-weight: $regural;
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                    color: $blue;
                }
            }
        }
        .service_content{
            h2{
                font-weight: $bold;
                font-size: rem-calc(30);
                line-height: rem-calc(36);
                color: $blue;
                margin: rem-calc(0 0 14 0);
                letter-spacing: rem-calc(1.5);
            }
            h3{
                font-weight: $bold;
                font-size: rem-calc(20);
                line-height: rem-calc(24);
                color: $blue;
                margin: rem-calc(0 0 16 0);
                letter-spacing: rem-calc(1.1);
            }
            p{
                font-weight: $regural;
                font-size: rem-calc(18);
                line-height: rem-calc(25);
                color: $blue;
            }
        }
    }
    @include breakpoints(small){
        .managed_services_wrap{
            padding: rem-calc(40 0 40 0);
            .services_block_left{
                width: 100%;
                padding: rem-calc(0 0 0 0);
            }
            .services_block_right{
                width: 100%;
                padding: rem-calc(30 0 0 0);
            }
        }
    }
    @include breakpoints(x-small){
        .managed_services_wrap{
            .service_box_full{
                margin: rem-calc(0 0 30 0);
            }
            .services_block_right{
                .service_box_half{
                    width: 100%;
                    ~ .service_box_half{
                        padding: rem-calc(30 0 0 0);
                    }
                }
            }
        }
    }
}