.image_content_block{
    &.common_bg{
        &:before{
            display: none;
        }
    }
}
.image_content_block_sec{
    padding: rem-calc(107 0 30 0);
    position: relative;
    .image_content_block_wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .side_image{
            width: 40.24%;
        }
        .content_block{
            width: 57.40%;
            padding: rem-calc(0 80 0 2);
            margin: rem-calc(-8 0 0 0);
            h2{
                font-size: rem-calc(40);
                line-height: rem-calc(48);
                font-weight: $bold;
                color: $white;
                letter-spacing: rem-calc(2);
                margin: rem-calc(0 0 12 0);
            }
            p{
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                font-weight: $regural;
                color: $white;
                margin: rem-calc(0 0 13 0);
            }
            ul{
                margin: 0;
            }
        }
        ~ .image_content_block_wrap{
            padding-top: rem-calc(94);
        }
    }
    @include breakpoints(tablet-portrait){
        .image_content_block_wrap{
            .content_block{
                padding: rem-calc(0 0 0 0);
            }
        }
    }
    @include breakpoints(small){
        padding: rem-calc(50 0 30 0);
        .image_content_block_wrap{
            .side_image{
                width: 100%;
            }
            .content_block{
                width: 100%;
                padding: rem-calc(30 0 0 0);
                margin: rem-calc(0 0 0 0);
                h2{
                    font-size: rem-calc(26);
                    line-height: rem-calc(32);
                }
                p{
                    font-size: rem-calc(16);
                    line-height: rem-calc(22);
                }
            }
        }
    }
}

.integration{
    .image_content_block{
        &.common_bg{
            &:before{
                display: block;
            }
        }
    }
}