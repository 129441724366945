.list_columns_block{
    background-color: $blue;
    padding: rem-calc(129 0 96 0);
    .list_columns_heading{
        padding: rem-calc(0 50 0 0);
        h2{
            font-size: rem-calc(40);
            line-height: rem-calc(48);
            font-weight: $bold;
            color: $white;
            letter-spacing: rem-calc(1.95);
            margin: rem-calc(0 0 13 0);
        }
        p{
            font-size: rem-calc(20);
            line-height: rem-calc(28);
            font-weight: $regural;
            color: $white;
        }
    }
    .list_columns_main{
        padding: rem-calc(91 0 0 120);
        max-width: 76%;
        .list_columns_box{
            padding: rem-calc(0 0 73 60);
            position: relative;
            .list_columns_icon{
                display: inline-block;
                vertical-align: top;
                position: absolute;
                left: 0;
                top: 0;
                width: rem-calc(40);
            }
            .list_columns_content{
                display: inline-block;
                vertical-align: top;
                width: 100%;
                padding: rem-calc(12 0 0 0);
                h3{
                    font-size: rem-calc(30);
                    line-height: rem-calc(36);
                    font-weight: $bold;
                    color: $orange;
                    letter-spacing: rem-calc(1.5);
                    margin: rem-calc(0 0 13 0);
                }
                p{
                    font-size: rem-calc(18);
                    line-height: rem-calc(25);
                    font-weight: $regural;
                    color: $white;
                }
                .primary_button{
                    background-color: transparent;
                    border-color: $white;
                    color: $white;
                    padding: rem-calc(11 26 13 27);
                    font-weight: $light;
                    font-size: rem-calc(20);
                    line-height: rem-calc(24);
                    text-transform: inherit;
                    margin: rem-calc(20 0 0 0);
                    letter-spacing: 0;
                    &:hover{
                        background-color: $orange;
                        border-color: $orange;
                    }
                }
            }
        }
    }
    &.two_column{
        padding-bottom: rem-calc(43);
        .list_columns_heading{
            padding: rem-calc(4 0 0 77);
            max-width: 78%;
        }
        .list_columns_main{
            padding: rem-calc(103 0 0 0);
            max-width: 85%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .list_columns_box{
                width: 47%;
                padding: rem-calc(0 0 78 60);
                .list_columns_content{
                    width: 100%;
                    h3{
                        font-size: rem-calc(25);
                        line-height: rem-calc(33);
                        font-weight: $bold;
                        color: $orange;
                        letter-spacing: rem-calc(1.2);
                        margin: rem-calc(0 0 14 0);
                    }
                    p{
                        font-size: rem-calc(20);
                        line-height: rem-calc(27);
                        font-weight: $regural;
                        color: $white;
                    }
                }
            }
        }
    }
    @include breakpoints(tablet-portrait){
        .list_columns_main{
            padding: rem-calc(80 0 0 0);
            max-width: 100%;
        }
        .list_columns_heading{
            padding: rem-calc(0 0 0 0);
        }
        &.two_column{
            .list_columns_heading{
                max-width: 100%;
                padding: rem-calc(0 0 0 0);
            }
            .list_columns_main{
                max-width: 100%;
            }
        }
    }
    @include breakpoints(small){
        padding: rem-calc(30 0 0 0);
        .list_columns_heading{
            h2{
                font-size: rem-calc(30);
                line-height: rem-calc(38);
                letter-spacing: rem-calc(0.9);
                margin: rem-calc(0 0 8 0);
            }
            p{
                font-size: rem-calc(18);
                line-height: rem-calc(26);
            }
        }
        .list_columns_main{
            padding: rem-calc(50 0 0 0);
            .list_columns_box{
                padding: rem-calc(45 0 50 0);
                .list_columns_content{
                    width: 100%;
                }
            }
        }
        &.two_column{
            padding-bottom: 0;
            .list_columns_main{
                padding: rem-calc(40 0 0 0);
                .list_columns_box{
                    width: 100%;
                    padding: rem-calc(40 0 50 0);
                    .list_columns_content{
                        width: 100%;
                    }
                }
            }
        }
    }
}