.list_block_sec{
    position: relative;
    &.pattern{
        background-image: url(../../../src/assets/images/rebel.png);
        &:before{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.92) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.92) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 );
        }
    }
    &.color{
        background-color: $blue;
    }
    .list_block_wrap{
        position: relative;
        padding: rem-calc(140 0 105 0);
        .list_block_heading{
            h2{
                font-size: rem-calc(40);
                line-height: rem-calc(48);
                color: $orange;
                font-weight: $bold;
                letter-spacing: rem-calc(2.1);
            }
            p{
                font-size: rem-calc(24);
                line-height: rem-calc(33);
                color: $off_white;
                font-weight: $bold;
                letter-spacing: rem-calc(1.25);
                max-width: 61%;
                margin: rem-calc(0 0 13 0);
            }
            &.center_postion{
                text-align: center;
            }
            &.right_postion{
                text-align: right;
            }
        }
        .list_block_main{
            padding: rem-calc(72 0 0 2);
            .grid-x{
                margin: rem-calc(0 -70);
            }
            .cell{
                padding: rem-calc(0 70 65);
            }
            .list_block_content{
                padding: rem-calc(50 0 0 31);
                position: relative;
                .list_block_icon{
                    position: absolute;
                    top: rem-calc(0);
                    left: rem-calc(-1);
                    margin: rem-calc(0 0 9 0);
                    width: rem-calc(40);
                    height: rem-calc(40);
                }
                h3{
                    font-size: rem-calc(25);
                    line-height: rem-calc(33);
                    color: $orange;
                    font-weight: $bold;
                    margin: rem-calc(0 0 12 0);
                    letter-spacing: rem-calc(1.3);
                }
                p{
                    font-size: rem-calc(18);
                    line-height: rem-calc(25);
                    color: $white;
                    font-weight: $regural;
                }
            }
        }
    }
    @include breakpoints(extra-large){
        .list_block_wrap{
            .list_block_main{
                .grid-x{
                    margin: rem-calc(0 -15);
                }
                .cell{
                    padding: rem-calc(0 15 60);
                }
            }
        }
    }
    @include breakpoints(small){
        .list_block_wrap{
            padding: rem-calc(20 0 10 0);
            .list_block_heading{
                h2{
                    font-size: rem-calc(28);
                    line-height: rem-calc(34);
                    letter-spacing: rem-calc(0.8);
                }
                p{
                    font-size: rem-calc(20);
                    line-height: rem-calc(26);
                    letter-spacing: rem-calc(1);
                    max-width: 100%;
                    margin: rem-calc(0 0 15 0);
                }
            }
            .list_block_main{
                padding: rem-calc(20 0 0 0);
                .list_block_content{
                    padding: rem-calc(50 0 0 0);
                    .list_block_icon{
                        left: 0;
                    }
                    h3{
                        font-size: rem-calc(24);
                        line-height: rem-calc(30);
                    }
                    p{
                        font-size: rem-calc(16);
                        line-height: rem-calc(22);
                    }
                }
            }
        }
    }
}